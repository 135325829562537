  <mat-card class="p-x-12 p-y-16">
    <div class="row">
      <div class="col-9 col-sm-9">
        <img [ngSrc]="imagePath ? baseImageUrl +  imagePath: './assets/images/item.png'"
             alt="" height="150" width="150">
      </div>
      <div class="col-3 col-sm-3" *ngIf="!status['code'] || status['code'] == 'NEW' || status['code'] == 'PROCESS'
             || status['code'] == 'PREPARED' || status['code'] == 'DRIVER'">
        @if (!creation) {
          <button mat-icon-button color="primary" (click)="emitShowDetails()">
            <mat-icon>more_vert</mat-icon>
          </button>
        }
        <button mat-icon-button color="warn" (click)="emitDelete()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <p>{{ itemNumber }}</p>
    <p class=" p-text-name">{{ itemName | truncate: [20, "..."] }}</p>
    <div class="col-md-12 col-sm-12 row">
      @if (barcode) {
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
          <p class="f-w-700"> {{ "RECIVING_ORDER_VIEW.ITEM_NUMBER" | translate }}</p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
          <p>{{ barcode }}</p>
        </div>
      }
      @for (row of details; track row.key) {
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
          <p class="f-w-700">{{ row.key| translate }}</p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
          <p>{{ row.value }}</p>
        </div>
      }
    </div>
    <div class="d-flex justify-content-center col-sm-12 col-md-12">
      @if (showCountAction) {
        <div class="col-sm-4 col-md-4 d-flex align-items-center justify-content-center">
          <button mat-icon-button color="accent" class="mat-large-icon-button" (click)="emitIncrement()">
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      }
      <div class="col-sm-4 col-md-4 d-flex align-items-center justify-content-center">
        <p class="f-w-600">{{ itemCount }}</p>
      </div>
      @if (showCountAction) {
        <div class="col-sm-4 col-md-4 d-flex align-items-center justify-content-center">
          <button mat-icon-button color="accent" class="mat-large-icon-button" (click)="emitDecrement()">
            <mat-icon>remove_circle</mat-icon>
          </button>
        </div>
      }
    </div>
  </mat-card>

