<div
  class="dashboard-card"
  [ngStyle]="{ 'border-left': '4px solid ' + cardColor }"
>
  <div class="card-header">
    <h3>{{ label | translate }}</h3>
    <div class="card-actions">
      <button
        *ngIf="hasFilter"
        mat-icon-button
        class="filter-button"
        (click)="openFilterDialog()"
      >
        <mat-icon>filter_list</mat-icon>
      </button>
      <button
        *ngIf="hasAction"
        mat-icon-button
        class="action-button"
        (click)="emitAction()"
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>
  <div class="card-content">
    <span class="value">{{ value | number }}</span>
  </div>
</div>
